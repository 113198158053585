/** @jsx jsx */
import { useState, useCallback } from 'react';
import { jsx, Box } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import { TabsTemplateProps } from './types';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { setTabsTemplateStyles } from './setStyles';
import TabItem from '../tabItem';
import TabButton from './TabButton';

const TabsTemplate: React.FC<TabsTemplateProps> = props => {
  const { tabItems, tabStyle, marginTop, marginBottom } = props;
  const [pt, pb] = useContainerPadding({
    top: marginTop,
    bottom: marginBottom,
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const handleItemClick = useCallback(
    index => setActiveIndex(index),
    [activeIndex],
  );

  const tabCount = tabItems?.length;

  const styles = setTabsTemplateStyles(tabStyle, tabCount, pt, pb);

  return (
    <Container containerSx={styles.containerStyles}>
      <Box sx={styles.buttonContainerStyles}>
        {tabItems &&
          tabItems.map((item, i) => (
            <TabButton
              key={i}
              item={item}
              style={tabStyle}
              isSelected={activeIndex === i}
              handleClick={() => handleItemClick(i)}
            />
          ))}
      </Box>
      <Box sx={styles.itemContainerStyles}>
        {tabItems &&
          tabItems.map((item, i) => (
            <TabItem
              key={i}
              item={item}
              style={tabStyle}
              isSelected={activeIndex === i}
            />
          ))}
      </Box>
    </Container>
  );
};

export default TabsTemplate;
