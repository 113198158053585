/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { setTabButtonStyles } from './setStyles';

const TabButton = ({ item, style, isSelected, handleClick }) => {
  const bpIndex = useBreakpointIndex({ defaultIndex: 0 });

  const label = item?.label;
  const labelMobile = item?.labelMobile;
  const displayedLabel = bpIndex === 0 && labelMobile ? labelMobile : label;

  const styles = setTabButtonStyles(style, isSelected);

  return (
    <button onClick={handleClick} sx={styles}>
      {displayedLabel}
    </button>
  );
};

export default TabButton;
