/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import TemplateRenderer from '../../components/tempateRenderer/TemplateRenderer';
import { TabItemProps } from './types';

const TabItem: React.FC<TabItemProps> = props => {
  const { item, isSelected } = props;
  const content = item?.tabContent;

  return (
    <Box sx={{ display: isSelected ? 'block' : 'none' }}>
      {content && <TemplateRenderer templates={[content]} />}
    </Box>
  );
};

export default TabItem;
