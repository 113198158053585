const setTabsTemplateStyles = (style, tabCount, pt, pb) => {
  const underlineStyles = {
    buttonContainerStyles: {
      display: 'inline-grid',
      gridTemplateColumns: `repeat(${tabCount}, auto)`,
      gridGap: 4,
      width: '100%',
      justifyContent: 'center',
      pb: ['60px', '80px'],
    },
    containerStyles: {
      pt,
      pb,
    },
    itemContainerStyles: {},
  };

  if (style === 'UNDERLINE') return underlineStyles;
};

const setTabButtonStyles = (style, isSelected) => {
  const buttonRest = {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: '0',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    boxSizing: 'border-box',
  };

  const underlineStyles = {
    ...buttonRest,
    px: 1,
    borderBottomStyle: 'solid',
    borderColor: isSelected ? 'primary' : 'transparent',
    borderWidth: '3px',
    transition: 'border-color .1s',
    fontSize: [2, 4, 4, 10],
    fontWeight: 'bold',
    '&:hover': {
      borderColor: 'primary',
      transition: 'border-color .1s',
    },
  };

  if (style === 'UNDERLINE') return underlineStyles;
};

export { setTabsTemplateStyles, setTabButtonStyles };
